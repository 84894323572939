import React from 'react';
import axios from 'axios';

const BASE_URL = window.__BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-access-token': window.__ACCESS_TOKEN,
  },
});

const { kakao } = window;

const geocoder = new kakao.maps.services.Geocoder();
const kakaoPlaceService = new kakao.maps.services.Places();

function haversineDistance(lat1, lon1, lat2, lon2) {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

function App() {
  const customPosition = React.useRef();
  const positionRef = React.useRef(window.__INITIAL_POSITION);
  const mapElementRef = React.useRef();
  const mapRef = React.useRef();
  const meRef = React.useRef();
  const [members, setMembers] = React.useState([]);
  const hongboRef = React.useRef(-1);
  const customOverlaysRef = React.useRef([]);
  const filterRef = React.useRef({
    isPromotionalProfile: false,
    isProgressEvent: false,
    isDifferentGender: false,
    distance: 400,
  });

  /**
   * Message React Native App
   */
  function handleMessagingReactNative(data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }

  /**
   * Set Center Function
   */
  function handleSetCenter(lat, lng) {
    mapRef.current.setCenter(
      new kakao.maps.LatLng(
        lat ?? positionRef.current.coords.latitude,
        lng ?? positionRef.current.coords.longitude,
      ),
    );
  }

  /**
   * Clear Custom Makers Function
   */
  function handeClearCustomOverlays() {
    customOverlaysRef.current.forEach((customOverlay) => {
      customOverlay.setMap(null);
    });
  }

  /**
   * Create Custom Maker Function
   * @param options
   * @returns {*[]}
   */
  function handleCreateCustomOverlay(options) {
    const latLng = new kakao.maps.LatLng(options.lat, options.lng);
    const customOverlays = [];
    if (['RESTAURANT', 'PARTNER'].includes(hongboRef.current)) {
      customOverlays.push(
        new kakao.maps.CustomOverlay({
          map: mapRef.current,
          position: latLng,
          content: `
<div
    class="w-[100px] h-[100px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;">
    <img src="./assets/bling_pink.gif" alt="bling" class="w-[100] h-[100px] absolute inset-0 m-auto" />
    <img src="./assets/marker_shop.png" alt="pin" class="w-[76px] h-[60px]" />
    <div class="w-[100px] h-[30px] absolute bottom-[-10px] rounded-full" style="background: radial-gradient(rgba(255, 73, 134, 0.5), transparent, transparent);"></div>
</div>
`,
          yAnchor: 1,
        }),
      );
    } else {
      if (options.children.length > 0) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="w-[100px] h-[100px] bg-[rgba(93,95,229,0.10)] mb-[-50%] rounded-full flex items-center justify-center" style="animation: scaleUp 1.5s ease-in-out alternate infinite;">
    <div class="w-[50px] h-[50px] bg-[rgba(120,121,241,0.28)] rounded-full"></div>
</div>
`,
            yAnchor: 1,
          }),
        );
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="relative w-[31px] h-[22px] mb-[-200px] ml-[50px]" onclick="window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'BUBBLE_CLICK', data: { items: [${options.children
                .map((child) => {
                  return `{
                    uid: '${child.uid}',
                    title: \`${child.intro}\`,
                    gender: '${child.gender}',
                    type: '${child.status > -1 ? 'HONGBO' : 'PROFILE'}',
                    distance: ${child.distance},
                    data: ${['RESTAURANT', 'PARTNER', 'MINE'].includes(child.type)
                      ? `
                            {
                                id: ${child.id},
                                type: '${child.type}',
                                public: '${child.public}',
                                avatar: '${child.avatar}',
                                profileImage: '${child.profile_image}',
                                imageUrl: '${child.avatar}',
                                title: \`${child.intro}\`,
                                marketName: '${child.market_name}',
                                contents: \`${child.content}\`,
                                isMe: ${child.me === 'Y'},
                                endAt: '${child.end_at}',
                                address: '${child.address}'
                            }
                          `
                      : `
                            {
                                id: ${child.id},
                                uid: ${child.uid},
                                type: '${child.type}',
                                public: '${child.public}',
                                avatar: '${child.avatar}',
                                profileImage: '${child.profile_image}',
                                title: \`${child.intro}\`,
                                contents: \`${child.content}\`,
                                isMe: ${child.me === 'Y'},
                                endAt: '${child.end_at}'
                            }
                          `
                    }
                }`;
                })
                .join(',')}] } }))">
  <div class="w-[20px] h-[20px] bg-[#EF5858] shadow-[0_5px_5px_rgba(0,0,0,0.25)] rounded-full leading-[20px] text-[12px] font-[800] text-[#FFFFFF] text-center absolute top-[-10px] right-[-10px]" style="text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);">
    ${options.children.length}
  </div>
  <img class="w-full h-full" src="./assets/speech_bubble.png" alt="bubble">
</div>
`,
            yAnchor: 1,
          }),
        );
      }
      if (['RESTAURANT', 'PARTNER'].includes(options.data.type)) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div
    class="w-[100px] h-[100px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;"
    onclick="window.ReactNativeWebView.postMessage(JSON.stringify({ type: '${'HONGBO_CLICK'}', data: { id: ${options.data.id
              }, type: '${options.data.type}', imageUrl: '${options.data.avatar
              }', title: \`${options.data.intro}\`, marketName: '${options.data.market_name
              }', contents: \`${options.data.content}\`, isMe: ${options.isMe
              }, endAt: '${options.data.end_at}', address: '${options.data.address
              }' } }))">
    <p class="absolute top-[-10px] rounded-[8px] bg-[#FF4986] px-[10px] pt-[5px] pb-[3px] text-[#FFFFFF] text-[12px] font-[600]">
        ${options.message}
    </p>
    <img src="./assets/bling_pink.gif" alt="bling" class="w-[100] h-[100px] absolute inset-0 m-auto" />
    <img src="./assets/marker_shop.png" alt="pin" class="w-[76px] h-[60px]" />
    <div class="w-[100px] h-[30px] absolute bottom-[-10px] rounded-full" style="background: radial-gradient(rgba(255, 73, 134, 0.5), transparent, transparent);"></div>
</div>
`,
            yAnchor: 1,
          }),
        );
      } else {
        const message = options.message
          ? `<div class="${options.isEvent
            ? 'bg-gradient-to-l from-[#8000FF] via-[#FF0FD5] via-[#EF5858] to-[#EF5858]'
            : options.isMe
              ? 'bg-[#545CDF]'
              : 'bg-[#000000]'
          } px-[10px] py-[3px] rounded-[8px] absolute ${!options.isEvent && options.isMe ? 'top-[-23px]' : 'top-[-15px]'
          } -translate-y-1/2 -translate-x-1/2 left-1/2 text-[10px] text-[#FFFFFF] font-[600] tracking-[-0.48px]">${options.message
          }</div>`
          : '';
        const online = options.isOnline
          ? '<div class="bg-[#4AE800] w-[10px] h-[10px] absolute top-0 right-0 rounded-full"></div>'
          : '';
        const bio =
          !options.isEvent && options.isMe
            ? `
<span class="absolute top-[-7px] left-1/2 -translate-x-1/2 text-center text-[#FFFFFF] font-[600] text-[10px] bg-[#545CDF] py-[2px] px-[10px] rounded-full">
나
</span>
`
            : '';
        const distance =
          !options.isEvent && options.isMe
            ? ''
            : `
<div class="p-[5px] bg-[#030303] rounded-[8px] text-[#999999] text-center font-[600] text-[12px] absolute leading-[10px] bottom-[2px] left-[-25%]">
  <p class="text-[#FFFFFF]">${options.distance}</p>
  <p class="text-[#999999]">m</p>
</div>
`;
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="w-[60px] h-[70px] bg-contain px-[5px] pt-[5px] pb-[15px] box-border relative" style="background-image: url(${options.isEvent
                ? './assets/marker.png'
                : options.isMe
                  ? './assets/marker_me.png'
                  : './assets/marker_off.png'
              })" onclick="
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        type: '${options.status === -1
                ? 'PROFILE_CLICK'
                : 'HONGBO_CLICK'
              }',
                        data: {
                          id: ${options.data.id},
                          uid: ${options.data.uid},
                          type: '${options.data.type}',
                          imageUrl: '${options.data.avatar}',
                          title: \`${options.data.intro}\`,
                          contents: \`${options.data.content}\`,
                          isMe: ${options.isMe},
                          endAt: '${options.data.end_at}',
                          gender: '${options.data.gender}',
                          job: '${options.data.job}'
                        }
                      })
                    )
                ">
  ${message}
  ${bio}
  ${online}
  <img src="${options.profileImage
              }" alt="profile" class="w-full h-full object-cover rounded-[12px]">
  ${distance}
</div>
`,
            yAnchor: 1,
          }),
        );
      }
    }

    return customOverlays;
  }

  /**
   * Create Custom Maker Function (디자인 수정된 마커)
   * @param options
   * @returns {*[]}
   */
  function handleCreateCustomOverlay2(options) {
    const latLng = new kakao.maps.LatLng(options.lat, options.lng);
    const customOverlays = [];
    if (['RESTAURANT', 'PARTNER'].includes(hongboRef.current)) { // 내 위치 홍보하기에서 가게홍보하기를 클릭했을때 보여질 지도
      customOverlays.push(
        new kakao.maps.CustomOverlay({
          map: mapRef.current,
          position: latLng,
          content: `
<div
    class="w-[100px] h-[100px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;">
    <img src="./assets/bling_pink.gif" alt="bling" class="w-[100] h-[100px] absolute inset-0 m-auto" />
    <img src="./assets/marker_shop.png" alt="pin" class="w-[76px] h-[60px]" />
    <div class="w-[100px] h-[30px] absolute bottom-[-10px] rounded-full" style="background: radial-gradient(rgba(255, 73, 134, 0.5), transparent, transparent);"></div>
</div>
`,
          yAnchor: 1,
        }),
      );
    } else { // 그 외 사용자가 존 화면 진입시 보여줄 지도
      if (options.children.length > 0) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="w-[100px] h-[100px] bg-[rgba(93,95,229,0.10)] mb-[-50%] rounded-full flex items-center justify-center" style="animation: scaleUp 1.5s ease-in-out alternate infinite;">
    <div class="w-[50px] h-[50px] bg-[rgba(120,121,241,0.28)] rounded-full"></div>
</div>
`,
            yAnchor: 1,
          }),
        );
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="relative w-[31px] h-[22px] mb-[-200px] ml-[50px]" onclick="window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'BUBBLE_CLICK', data: { items: [${options.children
                .map((child) => {
                  return `{
                    uid: '${child.uid}',
                    title: \`${child.intro}\`,
                    gender: '${child.gender}',
                    type: '${child.type ? child.type : 'PROFILE'}',
                    distance: ${child.distance},
                    data: ${['RESTAURANT', 'PARTNER', 'MINE', 'SULANG', 'ZONE_MEETING'].includes(child.type)
                      ? `
                            {
                                id: ${child.id},
                                type: '${child.type}',
                                public: '${child.public}',
                                avatar: '${child.avatar}',
                                profileImage: '${child.profile_image}',
                                imageUrl: '${child.avatar}',
                                title: \`${child.intro}\`,
                                marketName: '${child.market_name}',
                                contents: \`${child.type == 'ZONE_MEETING' ? '' : child.content}\`,
                                isMe: ${child.me === 'Y'},
                                endAt: '${child.end_at}',
                                address: '${child.address}'
                            }
                          `
                      : `
                            {
                                id: ${child.id},
                                uid: ${child.uid},
                                type: '${child.type}',
                                public: '${child.public}',
                                avatar: '${child.avatar}',
                                profileImage: '${child.profile_image}',
                                title: \`${child.intro}\`,
                                contents: \`${child.content}\`,
                                isMe: ${child.me === 'Y'},
                                user_type: \`${child.user_type}\`,
                                endAt: '${child.end_at}'
                            }
                          `
                    }
                }`;
                })
                .join(',')}] } }))">
  <div class="w-[20px] h-[20px] bg-[#EF5858] shadow-[0_5px_5px_rgba(0,0,0,0.25)] rounded-full leading-[20px] text-[12px] font-[800] text-[#FFFFFF] text-center absolute top-[-10px] right-[-10px]" style="text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);">
    ${options.children.length}
  </div>
  <img class="w-full h-full" src="./assets/speech_bubble.png" alt="bubble">
</div>
`,
            yAnchor: 1,
          }),
        );
      }
      if (['RESTAURANT', 'PARTNER'].includes(options.data.type)) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div
    class="w-[40px] h-[40px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;"
    onclick="window.ReactNativeWebView.postMessage(JSON.stringify({ type: '${'HONGBO_CLICK'}', data: { id: ${options.data.id
              }, type: '${options.data.type}', imageUrl: '${options.data.avatar
              }', title: \`${options.data.intro}\`, marketName: '${options.data.market_name
              }', contents: \`${options.data.content}\`, isMe: ${options.isMe
              }, endAt: '${options.data.end_at}', address: '${options.data.address
              }' } }))">
    <img src="./assets/ic_map_pin_shop.png" alt="pin" class="w-[23px] h-[33px]" />
</div>
`,
            yAnchor: 1,
          }),
        );
      } else if (['SULANG'].includes(options.data.type)) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div
    class="w-[40px] h-[40px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;"
    onclick="event.stopPropagation(); window.ReactNativeWebView.postMessage(JSON.stringify({ type: '${'SULANG_CLICK'}', data: { id: ${options.data.id
              }, type: '${options.data.type}', imageUrl: '${options.data.avatar
              }', title: \`${options.data.intro}\`, marketName: '${options.data.market_name
              }', contents: \`${options.data.content}\`, isMe: ${options.isMe
              }, endAt: '${options.data.end_at}', address: '${options.data.address
              }' } }))">
    <img src="./assets/ic_map_pin_restaurant.png" alt="pin" class="w-[23px] h-[33px]" />
</div>
`,
            yAnchor: 1,
          }),
        );
      } else if (['ZONE_MEETING'].includes(options.data.type)) {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div
    class="w-[40px] h-[40px] justify-center items-center flex relative"
    style="animation: spring 1.5s ease-in-out alternate infinite;"
    onclick="window.ReactNativeWebView.postMessage(JSON.stringify({ type: '${'ZONE_MEETING_CLICK'}', data: { id: ${options.data.id
              }, type: '${options.data.type}', imageUrl: '${options.data.avatar
              }', title: \`${options.data.intro}\`, marketName: '${options.data.market_name
              }', isMe: ${options.isMe
              }, endAt: '${options.data.end_at}', address: '${options.data.address
              }' } }))">
    <img src="./assets/ic_map_pin_meeting.png" alt="pin" class="w-[23px] h-[33px]" />
</div>
`,
            yAnchor: 1,
          }),
        );
      } else {
        customOverlays.push(
          new kakao.maps.CustomOverlay({
            map: mapRef.current,
            position: latLng,
            content: `
<div class="w-[23px] h-[33px] bg-contain px-[5px] pt-[5px] pb-[15px] box-border relative" style="background-image: url(${'./assets/ic_map_pin_user.png'
              })" onclick="
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        type: '${options.status === -1
                ? 'PROFILE_CLICK'
                : 'HONGBO_CLICK'
              }',
                        data: {
                          id: ${options.data.id},
                          uid: ${options.data.uid},
                          type: '${options.data.type}',
                          imageUrl: '${options.data.avatar}',
                          title: \`${options.data.intro}\`,
                          contents: \`${options.data.content}\`,
                          isMe: ${options.isMe},
                          endAt: '${options.data.end_at}',
                          gender: '${options.data.gender}',
                          user_type: \`${options.data.user_type}\`,
                          job: '${options.data.job}'
                        }
                      })
                    )
                ">
</div>
`,
            yAnchor: 1,
          }),
        );
      }
    }

    return customOverlays;
  }

  /**
   * Get Center from Map
   * @returns {*}
   */
  function handleGetCenter() {
    return mapRef.current.getCenter();
  }

  /**
   * Set Member Function
   */
  function handleSetMember() {
    if (hongboRef.current !== -1) {
      const center = handleGetCenter();
      const lat = center.getLat();
      const lng = center.getLng();
      geocoder.coord2Address(lng, lat, (event) => {
        handleMessagingReactNative({
          type: 'SET_HONGBO_RESPONSE',
          data: {
            lat,
            lng,
            address: event,
          },
        });
      });
      setMembers([
        {
          ...meRef.current,
          type: hongboRef.current,
          distance:
            haversineDistance(
              meRef.current.latitude,
              meRef.current.longitude,
              lat,
              lng,
            ) * 1000,
          latitude: lat,
          longitude: lng,
        },
      ]);
      return;
    }
    const bounds = mapRef.current.getBounds();
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    axiosInstance
      .get('/app/v2/zone/getMembers', {
        params: {
          x1: southWest.getLat(),
          x2: northEast.getLat(),
          y1: southWest.getLng(),
          y2: northEast.getLng(),
          latitude: positionRef.current.coords.latitude,
          longitude: positionRef.current.coords.longitude,
          customLatitude: customPosition.current?.latitude,
          customLongitude: customPosition.current?.longitude,
          ...filterRef.current,
        },
      })
      .then(({ data }) => {
        meRef.current = data.result_data.me;
        handleMessagingReactNative({
          type: 'SULANG_LIST',
          data: data.result_data.members.filter(it => it.type == 'SULANG'),
        });
        setMembers([
          ...data.result_data.members,
          {
            ...data.result_data.me,
            me: 'Y',
            distance: 0,
          },
        ]);
      });
  }

  /**
   * Location Search & Set Center
   * @param address
   * @param callback
   */
  function handleSetLocation(address, callback) {
    geocoder.addressSearch(address, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        handleSetCenter(result[0].y, result[0].x);
        callback?.(result);
      }
    });
  }

  /**
   * Initialize
   */
  React.useEffect(() => {
    /**
     * Initialize Kakao Map
     * @type {kakao.maps.Map}
     */
    mapRef.current = new kakao.maps.Map(mapElementRef.current, {
      center: new kakao.maps.LatLng(
        positionRef.current.coords.latitude,
        positionRef.current.coords.longitude,
      ),
      level: 5,
    });

    handleSetMember();

    /**
     * Set Member When Kakao Map Drag End Event
     */
    kakao.maps.event.addListener(mapRef.current, 'dragend', handleSetMember);

    /**
     * Set Member When Kakao Map Zoom Changed Event
     */
    kakao.maps.event.addListener(
      mapRef.current,
      'zoom_changed',
      handleSetMember,
    );

    /**
     * Set Member every 10 seconds
     * @type {number}
     */
    function createInterval() {
      return setInterval(() => {
        handleSetMember();
      }, 10000);
    }

    let interval = createInterval();

    /**
     * Message Event handler
     * @param message
     */
    function messageEventHandler(message) {
      if (message.data) {
        try {
          const data = JSON.parse(message.data);
          switch (data.type) {
            case 'SET_POSITION':
              positionRef.current = data.data;
              break;
            case 'FILTER_CHANGE':
              filterRef.current = data.data;
              handleSetMember();
              break;
            case 'SET_HONGBO_LOCATION': {
              if (data.data !== -1) {
                hongboRef.current = data.data;
                handleSetCenter();
                handeClearCustomOverlays();
                clearInterval(interval);
              } else {
                hongboRef.current = -1;
                interval = createInterval();
              }
              handleSetMember();
              break;
            }
            case 'SET_CURRENT_LOCATION':
              handleSetCenter();
              handleSetMember();
              break;
            case 'SET_LOCATION':
              handleSetLocation(data.data, handleSetMember);
              break;
            case 'UPDATE':
              handleSetMember();
              break;
            case 'SELECTED_LOCATION':
              customPosition.current = data.data;
              handleSetCenter(data.data.latitude, data.data.longitude);
              handleSetMember();
              break;
            case 'SEARCH_ADDRESS':
              kakaoPlaceService.keywordSearch(
                data.data,
                function (result, status) {
                  if (status === kakao.maps.services.Status.OK) {
                    handleMessagingReactNative({
                      type: 'SEARCH_ADDRESS_RESPONSE',
                      data: result,
                    });
                  }
                },
              );
              break;
          }
        } catch (error) { }
      }
    }

    /**
     * Set Messaging Event Listener
     */
    window.addEventListener('message', messageEventHandler);
    document.addEventListener('message', messageEventHandler);

    return () => {
      /**
       * Clear Kakao Map Drag Event Listener
       */
      kakao.maps.event.removeListener(
        mapRef.current,
        'dragend',
        handleSetMember,
      );

      /**
       * Set Member When Kakao Map Zoom Changed Event
       */
      kakao.maps.event.removeListener(
        mapRef.current,
        'zoom_changed',
        handleSetMember,
      );

      /**
       * Clear Interval
       */
      if (interval) {
        clearInterval(interval);
      }

      /**
       * Clear Messaging Event Listener
       */
      window.removeEventListener('message', messageEventHandler);
    };
  }, []);

  /**
   * Set Maker When Updated Members
   */
  React.useEffect(() => {
    handeClearCustomOverlays();
    customOverlaysRef.current = [...members]
      .sort((a, b) => (a.me === 'Y' ? -1 : a.id - b.id))
      .reduce((p, c) => {
        const pixelPoint1 = mapRef.current
          .getProjection()
          .pointFromCoords(new kakao.maps.LatLng(c.latitude, c.longitude));
        const similarDistanceMember = p.find((e) => {
          const pixelPoint2 = mapRef.current
            .getProjection()
            .pointFromCoords(new kakao.maps.LatLng(e.latitude, e.longitude));
          const distanceInPixels = Math.sqrt(
            Math.pow(pixelPoint1.x - pixelPoint2.x, 2) +
            Math.pow(pixelPoint1.y - pixelPoint2.y, 2),
          );
          return distanceInPixels < 100;
        });
        if (similarDistanceMember) {
          similarDistanceMember.children.push(c);
        } else {
          p.push({ ...c, children: [] });
        }
        return p;
      }, [])
      .reduce((p, c) => {
        return [
          ...p,
          ...handleCreateCustomOverlay2({
            status: c.status,
            type: c.type,
            lat: c.latitude,
            lng: c.longitude,
            profileImage: BASE_URL + '/' + c?.avatar,
            message: c.intro,
            distance: Number(c.distance.toFixed(2)),
            isEvent: c.event === 'Y',
            isOnline: c.online === 'Y',
            isMe: c.me === 'Y',
            children: c.children,
            data: c,
          }),
        ];
      }, []);
  }, [members]);

  return <div ref={mapElementRef} className="w-screen h-screen" onClick={() => {
    handleMessagingReactNative({
      type: 'HIDE_SULANG_CAROUSEL',
      data: {
      },
    });
  }} />;
}

export default App;
